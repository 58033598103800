import * as React from "react";
import { useStaticQuery, graphql, Link } from "gatsby"

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Definitions from '../../common/definitions';

function GalleryPage() {
  const data = useStaticQuery(
    graphql`
      query galleryDataQuery {
        allStrapiCountries {
          edges {
            node {
              id
              strapiId
              CountryName
              slug
            }
          }
        }
        allStrapiCities {
          edges {
            node {
              id
              district {
                id
                state
              }
            }
          }
        }
        allStrapiStates {
          edges {
            node {
              country {
                id
              }
              strapiId
            }
          }
        }
      }
    `
  );

  const getCitiesInCountry = (data, countryId) => {
    let countryStates = [];
    // get all states in the country
    for (let stateIdx = 0; stateIdx < data.allStrapiStates.edges.length; stateIdx++) {
      if (data.allStrapiStates.edges[stateIdx].node.country.id === countryId) {
        countryStates.push(data.allStrapiStates.edges[stateIdx].node.strapiId);
      }
    }
    let count = 0;
    // now check the states of the cities
    for (let cityIdx = 0; cityIdx < data.allStrapiCities.edges.length; cityIdx++) {
      if (countryStates.includes(data.allStrapiCities.edges[cityIdx].node.district.state)) {
        count++;
      }
    }
    return count;
  }

  return(
  <Layout>
    <SEO title="Home" />
    <h1>Länder in der Galerie</h1>
    <ul>
      {data.allStrapiCountries.edges.map((country) => {
        return(
          <li key={country.node.id}>
            <Link to={Definitions.GalleryPagesBasePath.concat(country.node.slug.toLowerCase(),'/')}>{country.node.CountryName}</Link>&nbsp;({getCitiesInCountry(data, country.node.strapiId)})
          </li>
        );
      })}
    </ul>

  </Layout>
  );
}

export default GalleryPage